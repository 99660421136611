import React, { useState } from "react";
import styles from './Navi.module.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>

  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const Navi: React.FC = (props) => {

	const classes = useStyles();

	return ( 

	<div className={classes.root} data-testid="Navi">
		
		<AppBar position="static">
        	<Toolbar>
          		<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
				<MenuIcon />
          		</IconButton>
          		<Typography variant="h6" className={classes.title}>
            		News
          		</Typography>
          		<Button color="inherit">Login</Button>
        	</Toolbar>
    	</AppBar>

	</div>
	);
}

export default Navi;
