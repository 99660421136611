/* 

This file is made to store all the utility fuctions used across the application, is usually general functions that can be reused on different components. 

*/

import React from 'react';
import { toast } from 'react-toastify';

export const  routeSubdomain = (sub : string): void  => {
    let parts : String[];
    process.env.REACT_APP_URL ? parts = process.env.REACT_APP_URL.split("//") : parts = [".","."];
  
    window.location.href = parts[0] + "//dev." + parts[1];
}

// Toastify custom notifications.
// TO DO: add different icons and titles to the different notifications.

export const  toastAlert = (msg : string, op: string): void  => {
 
    switch(op) {

        case "notify": 

            toast(msg);
            break;
        
        case "alert":

            toast(msg);
            break;

        case "error":

            toast(msg);
            break;
        
        default:
            break;

    }

}
