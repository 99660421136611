import React from 'react';
import styles from './Dev.module.scss';
import Grid from '@material-ui/core/Grid';


const Dev: React.FC = () => (
  <Grid className={styles.Dev} data-testid="Dev">
    
    <Grid> 
      <Grid> Soy un left aside </Grid>
      <Grid> Soy el DEV </Grid>
      <Grid> Soy un right aside </Grid>
    </Grid>

  </Grid>
);

export default Dev;
