import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import history from './history'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Grid, { GridSpacing } from '@material-ui/core/Grid';

import HomeRoutes from './components/Home/Home';
import DevRoutes from './components/Dev/Dev';
import NotFound from './components/NotFound/NotFound';
import Navi from "./components/commons/Navi/Navi";
import Footer from './components/commons/Footer/Footer';

import {routeSubdomain, toastAlert} from './utility';

//finding out the subdomain. 
let host = window.location.host;
let parts = host.split(".")[0].split(":");

let subdomain = parts[0]
// If  the first part is "mayeru" or "www" then we dont have a subdomain.
if (parts[0] === "mayeru" || parts[0] === "www" || parts[0] === "localhost") {
  subdomain = ".";
}


toast.configure();

function App() {

  return (
    
    <Router history={history}>
	    <Grid >
      
        {/* MENU */}

		    <Navi/> 

{/*         <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/dev">Dev</Link> 
          </li>
          <button onClick={() => toastAlert("test message", "notify")}> TRY ME! </button>
        </ul>
 */}
        <Switch>

          {subdomain === "." &&
            <Route exact path="/" component={HomeRoutes} />
          }

          {subdomain === "dev" &&
            <Route exact path="/" component={DevRoutes} />
          }

          <Route path='/dev' component={() => routeSubdomain("dev")}/>

          <Route path="*" component={NotFound} />

        </Switch>

			  {/* FOOTER */}
			  <Footer />

        </Grid>
    </Router>
    
  );
}



export default App;
