import React from 'react';
import styles from './NotFound.module.scss';
import Grid from '@material-ui/core/Grid';


const NotFound: React.FC = () => (

  <Grid className={styles.NotFound} data-testid="NotFound">
    
    <Grid> 
    <h1> PAGE NOT FOUND</h1>
    </Grid>

  </Grid>

);

export default NotFound;
