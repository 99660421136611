import React from "react";
import styles from "./Home.module.scss";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
	  color: theme.palette.text.secondary,
    },
  }),
);

const Home: React.FC = (props) => {
	const classes = useStyles();

	return (
		
	
		<Grid className={styles.Home} data-testid="Home" >
			<Grid xs={12}>
				CLOUDS
			</Grid>

			<Grid container spacing={1}> 

			{/* COVER */}
			<Hidden xsDown>
				<Grid  xs={3}  >
						LEFT
				</Grid>
			</Hidden>


			<Grid xs={12} md={6}>
				<Paper className={classes.paper}>
					MID
				</Paper>
			</Grid>
			
			<Hidden xsDown>
				<Grid  xs={3} >
						LEFT
				</Grid>
			</Hidden>

			</Grid>
	
			<Grid>

			</Grid>

		</Grid>
	
	  );
} 
  
  export default Home;
