import React, { useState } from "react";
import styles from './Footer.module.scss';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
	  color: theme.palette.text.secondary,
	  height: '100px',
    },
  }),
);

const Footer: React.FC = (props) => {
	const classes = useStyles();

	return ( 

	<Grid className={styles.Footer} data-testid="Footer">
		
		<Paper className={classes.paper} elevation={0} variant="outlined"> 
		
		FOOTER

		</Paper>

	</Grid>
	);
}

export default Footer;
